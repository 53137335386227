/* -------------------------------------------------------------------------- */
/*                                  Variables                                 */
/* -------------------------------------------------------------------------- */

// TODO: add function for time to auto logout

// Default settings
const lilRedDefaults = {
	lilRedApiUrl: null,
	logoutIfStale: true,
	daysTillLogout: 10,
};

// Settings
let lilRedSettings;

// Local storage keys
const AUTH_TOKEN = "lilRedAuthToken";
const LAST_LOGIN = "lilRedLastLogin";

/* -------------------------------------------------------------------------- */
/*                              Helper Functions                              */
/* -------------------------------------------------------------------------- */

function dispatch(name, detail, bubbles = true) {
	if (typeof detail === "string" && /^ERROR/.test(detail)) {
		// eslint-disable-next-line no-console
		console.error(name, detail);
	} else {
		// eslint-disable-next-line no-console
		console.log(name, detail);
	}
	document.dispatchEvent(
		new CustomEvent(name, {
			bubbles: bubbles,
			detail: detail,
		})
	);
}

/* -------------------------------------------------------------------------- */
/*                               Private Methods                              */
/* -------------------------------------------------------------------------- */

/**
 * fetcher - basic fetch routine
 * @private
 */
async function fetcher(url, options) {
	/* eslint-disable no-console */
	try {
		let response = await fetch(url, options);
		console.log(`RESPONSE:lilFetch for ${url}`, response);
		if (response.status !== 200)
			throw new Error(`fetch fail status: ${response.status}`);
		const contentType = response.headers.get("content-type");
		// Return JSON if it is JSON otherwise text
		const result =
			contentType && contentType.indexOf("application/json") !== -1
				? await response.json()
				: await response.text();
		console.log(`RESULT:lilFetch for ${url}`, result);
		return result;
	} catch (err) {
		console.error(`ERROR:lilFetch for ${url}`, err);
		return null;
	}
	/* eslint-enable no-console */
}

/**
 * lilRedAuth - lilRed Targeted Fetch
 * @private
 */
async function lilAuth(username, password) {
	if (!lilRedSettings && lilRedSettings.lilRedApiUrl) return false;
	const url = lilRedSettings.lilRedApiUrl + "/login";
	const options = {
		method: "POST",
		headers: {
			"Content-Type": "application/json;charset=utf-8",
		},
		body: JSON.stringify({
			username: username,
			password: password,
		}),
	};
	try {
		let response = await fetch(url, options);
		if (response.status === 200 && response.headers.get("authorization")) {
			const token = response.headers.get("authorization");
			localStorage.setItem(AUTH_TOKEN, token);
			localStorage.setItem(LAST_LOGIN, new Date().toISOString());
			// eslint-disable-next-line no-console
			dispatch("lilRed_login", "success");
			return token;
		}
	} catch (err) {
		dispatch("lilRed_login", "fail");
		return null;
	}
}

/**
 * lilFetch - lilRed Targeted Fetch
 * @private
 */

async function lilFetch(settings) {
	// settings = {api,method,body,jsonStringify,token}
	settings = { jsonStringify: true, method: "GET", ...settings };

	if (!lilRedSettings && lilRedSettings.lilRedApiUrl) return false;

	if (!lilRedSettings.lilRedApiUrl || !settings.api) return false;

	/* eslint-disable no-unused-vars */
	const url = lilRedSettings.lilRedApiUrl + settings.api;
	const options = {
		method: settings.method,
		headers: {},
	};
	// JSON string it by default; this allows for just passing a formData object if desired
	if (settings.jsonStringify) {
		settings.body = settings.body && JSON.stringify(settings.body);
		options.headers["Content-Type"] = "application/json;charset=utf-8";
	}
	// Add body if there
	if (settings.body) options.body = settings.body;
	// If has api is more than just the base and it is not public access, then add authorization headers token
	if (settings.api !== "/" && !/public/.test(settings.api)) {
		// Check for auth token
		const authToken =
			settings.token ||
			lilRedSettings.token ||
			localStorage.getItem(AUTH_TOKEN);

		if (authToken) {
			// Add authorization headers
			options.headers.Authorization = authToken;
		} else {
			// If no auth token then stop script
			dispatch("lilRed_lilFetch", "ERROR: auth token missing");
			return null;
		}

		if (lilRedSettings.logoutIfStale) {
			// Check last login and logout if too old
			const lastLogin = Date.parse(localStorage.getItem(LAST_LOGIN));
			const now = new Date();
			const daysTillLogout = lilRedSettings.daysTillLogout || 10;
			const earliestAllowedLogin = Date.parse(
				new Date(now.setDate(now.getDate() - daysTillLogout))
			);
			const isStale =
				isNaN(lastLogin) ||
				isNaN(earliestAllowedLogin) ||
				lastLogin < earliestAllowedLogin;

			if (isStale) {
				dispatch(
					"lilRed_lilFetch",
					`ERROR: auth token stale. Last Login: ${lastLogin}`
				);
				logout();
				return null;
			}
		}

		// Add authorization headers
		options.headers.Authorization = authToken;
	}

	let response = await fetcher(url, options);
	return response;
}

/**
 * lilGet - shorthand
 * @private
 */

const lilGet = (api, token) => lilFetch({ api: api, token: token });

/**
 * lilPost - shorthand
 * @private
 */
const lilPost = (api, body, token) =>
	lilFetch({
		api: api,
		method: "POST",
		body: body,
		token: token,
	});

/**
 * lilPut - shorthand
 * @private
 */
const lilPut = (api, body, token) =>
	lilFetch({
		api: api,
		method: "PUT",
		body: body,
		token: token,
	});

/**
 * lilDelete - shorthand
 * @private
 */
const lilDelete = (api, body, token) =>
	lilFetch({
		api: api,
		method: "DELETE",
		body: body,
		token: token,
	});

/* -------------------------------------------------------------------------- */
/*                      Main LilRed Public Functions                          */
/* -------------------------------------------------------------------------- */

export function init(lilRedOptions) {
	// Merge user options with defaults
	// settings = extend(defaults, options || {});
	lilRedSettings = { ...lilRedDefaults, ...lilRedOptions };

	// eslint-disable-next-line
	console.log("🐺 Initializing Lil Red Fetch", lilRedSettings);

	// Code goes here...
	//
}

const status = async () => {
	const result = await lilGet("/");
	dispatch("lilRed_status", result);
	return result;
};
const isAdmin = () => lilGet("/users/me/isadmin");
const login = (username, password) => lilAuth(username, password);
const logout = () => {
	// eslint-disable-next-line no-console
	dispatch("lilRed_logout", "You have been logged out of Lil Red");
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(LAST_LOGIN);
};
const me = () => lilGet("/users/me");

const bookings = {
	slots: () => lilGet("/bookings/myAvailableSlots"),
	get: () => lilGet("/events/me"),
	add: (id) => lilPost("/bookings/bookMeIntoGame", { gameId: Number(id) }),
	delete: (id) =>
		lilDelete("/bookings/removeMeFromGame", {
			gameId: Number(id),
		}),
};

const favorites = {
	get: () => lilGet("/events/me/favorites"),
	add: (id) =>
		lilPost("/events/me/favorite/create", {
			eventId: Number(id),
		}),
	delete: (id) =>
		lilDelete("/events/me/favorite/delete", {
			eventId: Number(id),
		}),
};

const password = {
	// TODO: sort all this out and make sure it works
	set: (id, password) =>
		lilPost("/users/setMyPassword", {
			userId: Number(id),
			password: password,
		}),
	resetRequest: (email) =>
		lilPost("/users/resetPasswordRequest", {
			email: email,
		}),
	request: (emailAddress, emailBody, emailSubject) =>
		lilPost("/password/request", {
			emailAddress: emailAddress,
			emailBody: emailBody,
			emailSubject: emailSubject,
		}),
	reset: (emailAddress, password, uuid) =>
		lilPost("/password/reset", {
			emailAddress: emailAddress,
			password: password,
			uuid: uuid,
		}),
	confirm: (password, token) =>
		lilPost("/users/confirmPasswordRequest", {
			password: password,
			token: token,
		}),
};

const events = {
	me: () => lilGet("/events/me"),
	spaces: () => lilGet("/events/spaces/public"),
	space: (id) => lilGet(`/events/${id}/spaces/public`),
	find: (id) => lilPost("/events/find", { id: Number(id) }),
	all: () => lilGet("/events/all"),
	category: (category) => lilGet(`/events/category/${category}`),
	count: () => lilGet("/events/count"),
	create: (body) => lilPut("/users/setMyPassword", body),
	uploadImage: (formData) => {
		// must contain file and eventID as formData
		lilFetch({
			api: "/events/image",
			method: "POST",
			body: formData,
			jsonStringify: false,
		});
	},
	currentYear: (length, offset) => lilGet(`/events/page/${length}/${offset}`),
	since: (epochtime) => lilGet(`/events/since/${epochtime}`),
	public: {
		all: () => lilFetch("/events/all/public"),
		currentYear: (length, offset) =>
			lilGet(`/events/page/public/${length}/${offset}`),
	},
	// priorYear "events-for-year-controller" which isn't as useful
};

const admin = {
	roles: {
		add: (id, role) =>
			lilPost("/events/addRoleToUser", {
				userId: Number(id),
				role: role,
			}),
		delete: (id, role) =>
			lilPost("/events/removeRoleFormUser", {
				userId: Number(id),
				role: role,
			}),
	},
	users: {
		all: () => {},
		create: () => {},
		findById: () => {},
		findByEmail: () => {},
		setPassword: () => {},
	},
	bookings: {
		add: () => {},
		delete: () => {},
		setGm: () => {},
	},
};

/* ------------------------- Export basic functions ------------------------- */
export { fetcher, lilFetch, lilGet, lilPost, lilPut, lilDelete };

/* -------------------------- Export user functions ------------------------- */
export {
	status,
	isAdmin,
	login,
	logout,
	me,
	bookings,
	favorites,
	password,
	events,
	admin,
};
